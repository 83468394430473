// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import './add_jquery'
import * as bootstrap from "bootstrap"

window.bootstrap = bootstrap;

let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
  return new bootstrap.Popover(popoverTriggerEl)
})



document.addEventListener("turbo:frame-render", function (e) {
	console.log("run recaptcha reload after turbo:frame-render");
	recaptchaload();
    })

document.addEventListener("turbo:render", function (e) {
	console.log("run recaptcha reload after turbo:render");
	recaptchaload();
    })

function recaptchaload(){
	console.log("recaptcha render");
	target = document.getElementById('recaptcha');
	if (target == null) {
		console.log("RECAPTCHA NIE ZNALEZIONA")
		return;
	}
	if (target.childNodes.length === 0) {
		console.log("RECAPTCHA JEST PUSTA, WYPEŁNIAM")
 	        grecaptcha.enterprise.render('recaptcha', {'sitekey' : '6Le_bVMkAAAAAJC3A2wDLS3yD8qRtXtZ8TjcVamI', 'action': 'LOGIN'});
	}
}

function hideMenu(e){
	menu = document.getElementById("navbarNav");
	menu.classList.remove("show");
	console.log("ukryto menu");
}

window.hideMenu = hideMenu


//$(document).on("click", 'a[href*="#"]', function(e) {
//    console.log("wywołano funkcję click!");
//    var id = $(this).attr('href');
//    console.log(id);

//    if (window.location.pathname === id.charAt(0) ) {
//	    console.log("jesteś na głównej");
//	    path = id.substring(1);
//	    console.log(path);
//            var pos = $(path).offset().top;
//	    $('body, html').animate({scrollTop: pos - 200}, 2000);

//	    return;
//    }

//    console.log("nie jesteś na głównej");


    // target element
    //var $id = $(id);

//    if (id.length === 0) {
//        return;
//    }


    // prevent standard hash navigation (avoid blinking in IE)
//    e.preventDefault();

    // top position relative to the document
//    var pos = id.offset().top;

    // animated top scrolling
//    $('body, html').animate({scrollTop: pos - 200});
//    console.log("test nowej funkcji");
//});
